<template>
  <div class="user-my-dev" v-wechat-title="'我申请的开发版'">
    <van-list
      v-if="devList != ''"
      v-model="loading"
      :finished="finished"
      finished-text="暂无更多数据"
      @load="onLoad"
    >
      <div class="user-my-dev-list">
        <div v-for="(item,index) in devList" :key="index">
          <span> <v-img :attachment-id="item.coverAttmId" :default-img="require('@/assets/img/kctp.png')" alt="dev" /></span>
          <div>
            <strong>{{ item.devboardName }}</strong>
            <p>SOC：{{ item.socName }}</p>
            <p>厂商：{{ item.corpName }}</p>
            <p>申请时间：{{ item.createTime }}</p>
          </div>
        </div>
      </div>
    </van-list>
    <van-empty
      v-if="isEmpty"
      class="empty-image"
      :image="require('@/assets/img/4886@2x.png')"
      description="暂无更多数据"
    />
  </div>
</template>

<script>
import { getDevboardApplyList } from '@/api/devboard'
import VImg from '@/components/VImg'

export default {
  components: { VImg },
  data () {
    return {
      page: {
        current: 1,
        size: 10,
        pageSize: 1
      },
      loading: false,
      finished: false,
      devList: [],
      isEmpty:false
    }
  },
  mounted () {
    this.loadGetMyDev()
  },
  methods: {
    onLoad () {
      if (!this.devList || !this.devList.length) {
        this.finished = true
        return
      }
      if (this.page.pageSize === this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadGetMyDev()
    },
    // 解决方案列表
    loadGetMyDev () {
      const { page } = this
      const data = {}
      getDevboardApplyList(page, data).then((res) => {
        this.loading = false
        this.devList = [...this.devList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false
        if(this.devList == ''){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-my-dev {
  padding: 16px;
  min-height: calc(100vh - 56px);
  background: #f6f6f6;
  .user-my-dev-list {
    > div {
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      margin-bottom: 12px;
      padding: 24px 16px;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      >span{
        width: 90px;
        height: 90px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        >img{
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }
      >div{
        width: 204px;
        >strong{
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        >p{
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          margin-bottom: 2px;
          overflow: hidden;
          max-height: 36px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

</style>
<style>
.empty-image .van-empty__image{
  width: 94px;
  height: 81px;
}
</style>
