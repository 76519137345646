import { PortalRequest as request } from '@/utils/request'

// 开发板申请分页查询
export const getDevboardApplyList = (params, data) => {
  return request({
    url: '/devboard-apply/page-list-me',
    method: 'post',
    params: params,
    data
  })
}
